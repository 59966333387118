







































// --- Vue & Template imports ---
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PageAppBar extends Vue {

  items = [
    /*
    {
      icon: this.$store.getters['app/icons'].login,
      text: 'Login',
      to: '/login',
    },
    */
  ];

  get getCurrentPage() {
    return this.$route.path;
  }

}
